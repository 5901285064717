<template>
  <div class="hidden md:block mt-sm border-y-sm border-charcoal-default text-center">
    <NuxtLink
      v-for="topic in topics"
      :key="topic.name"
      :to="topic.link"
      :class="[
        'py-sm px-sm border-l-sm border-charcoal-default last:border-r-sm inline-block font-bold text-charcoal-default hover:bg-grey-light',
        topic.link === route.path && 'text-mkm-blue-light',
      ]"
    >
      <Stack direction="row" align="center" gap="xs">
        <Icon :name="topic.slug" :size="16" :class="[topic.link === route.path && 'text-mkm-blue-light']" />
        <p class="uppercase font-semibold text-base mt-3xs lg:text-md">
          {{ topic.name }}
        </p>
      </Stack>
    </NuxtLink>
  </div>

  <div class="px-xs sm:px-xs mt-md mb-xs sm:mb-none md:hidden">
    <MobileMenu :items="topicsOptions(topics)" hidden-sign-out />
  </div>
</template>

<script setup lang="ts">
import type { Component, Page } from "@bloomreach/spa-sdk";

const { component, page } = defineProps<{ component: Component; page: Page }>();
const document = (page: Page, component: any) => page.getContent(component.getModels().menu);
const route = useRoute();

const generateSlug = (str: string) => {
  return (
    str
      ?.trim()
      .toLowerCase()
      .replace("&", "and")
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-") || ""
  );
};

const menu = document(page, component) as any;
const topics = menu?.getItems().map((item: any) => {
  const link = item.getLink() === undefined ? "" : item.getLink().href;
  return {
    name: item.getName(),
    link: link,
    slug: generateSlug(item.getName()),
    status: link !== "",
    selected: item.isSelected(),
  };
});

const topicsOptions = (topics: any) => {
  return topics.map((topic: any) => {
    return {
      label: topic.name,
      value: topic.link,
      href: topic.link,
      key: topic.link,
    };
  });
};
</script>
